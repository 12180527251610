import React from "react";
import "./FAQStyling.css";
import Faq from "react-faq-component";

const FAQSection = () => {
  const data = {
    title: "Ofte stilte spørsmål",
    rows: [
      {
        title: "Hvorfor trene selvforsvar?",
        content: `Selvforsvar er noe alle bør ha en viss basiskunnskap om, det har mange fordeler. La oss starte med det åpenbare: samfunnet er i endring, vi hører stadig om tilfeldige offer og angrep i mediene. Er du heldig vil du kunne gå gjennom livet uten å bli berørt av slike hendelser, men statistikken tilsier at stadig flere blir utsatt for blind vold, voldtekter og andre uønskede og uprovoserte hendelser. I slike situasjoner, hvis du ikke er forberedt på å agere, vil reaksjonen din sannsynligvis være panikk og stress. Ved å lære selvforsvar, vil du trene kropp og sinn til å reagere riktig slik at du kan holde hodet kaldt og løse situasjonen på best mulig måte. Selvforsvarstrening vil ikke bare forberede deg psykisk på at noe kan skje, og hva du bør gjøre i ulike situasjoner, men vil også øke muskelstyrken, kondisjonen, reaksjonstid og gi deg innøvde reflekser, som er nøkkelaspekter for å komme godt ut av en uønsket situasjon.
    `,
      },
      {
        title: "Hvorfor er Kali Sikaran så godt egnet som selvforsvar?",
        content:
          "Kali Sikaran springer opprinnelig ut fra en verden der alle måtte klare seg selv, nærmere bestemt på Fillipinene en gang for lenge siden. Kali Sikaran er komplekst selvforsvar å inneholder elementer som forsvar mot ulike våpen som kniv, batong og andre slagvåpen så vel som ulike angrep med bare nevene. Du lærer å forsvare deg og dine ut fra dine fysiske og psykiske forutsetninger, det finnes ikke en fasit som passer alle.",
      },
      {
        title: "Hvem kan trene selvforsvar?",
        content: `Det korte svaret er alle over 16 år. Treningene, øvelsene og alt vi gjør kan lett tilpasses den enkeltes behov. En kraftig mann på 120 kilo har andre utfordringer når det kommer til selvforsvar en ei lett dame på 52 kilo, men våre instruktører har lang erfaring og vet hva som fungerer for ulike kropper og personligheter. Enkelte klubber har tilpassede treninger for barn.`,
      },
      {
        title: "Hva kan jeg forvente på trening?",
        content:
          "Hvis du har lyst til å teste selvforsvar hos oss er du hjertelig velkommen. Vi har rom for alle. Kom innom og prøv, så jobber vi med deg uansett erfaring eller treningsnivå. Våre erfarne og høyt graderte instruktører har kunnskapen og ekspertisen som trengs, og vi er her for å hjelpe deg med å bli tryggere og sterkere. Som nybegynner er du trygg, noen er redd for skader, men sjansen for å bli skadet er minimal. Folkesporten fotball har mye høyere skadefrekvens enn oss. Likevel byr vi på hard fysisk trening, men vi forstår at alle har ulike begrensninger og grenser og tar hensyn til hver enkelt.",
      },
      {
        title: "Er det bare å møte opp?",
        content:
          "Ja, enkelt og greit! Hvis du vil kan du finne din klubb og ta kontakt med dem direkte. Da vet de at du kommer og kan ta vare på deg fra første stund. ",
      },
      {
        title: "Hva trenger jeg til første trening?",
        content:
          "Vanlige treningsklær, dvs. bukse, shorts eller tights og ei t-skjorte, og ei vannflaske.",
      },
    ],
  };

  const datase = {
    title: "Vanliga frågor",
    rows: [
      {
        title: "Varför träna självförsvar?",
        content: `Självförsvar är något alla bör ha grundläggande kunskaper i, det har många fördelar. Låt oss börja med det uppenbara: samhället förändras, vi hör ständigt om slumpmässiga offer och attacker i medierna. Du kan ha tur och gå genom livet utan att påverkas av sådana händelser, men statistiken tyder på att allt fler utsätts för blind våld, våldtäkter och andra oönskade och oprovocerade händelser. I sådana situationer, om du inte är förberedd på att agera, kommer din reaktion sannolikt att vara panik och stress. Genom att lära dig självförsvar tränar du kropp och sinne att reagera korrekt så att du kan hålla huvudet kallt och lösa situationen på bästa möjliga sätt. Självförsvarsträning förbereder dig inte bara mentalt på att något kan hända och vad du bör göra i olika situationer, utan ökar också muskelstyrkan, konditionen, reaktionstiden och ger dig inövade reflexer, som är nyckelaspekter för att komma ut väl ur en oönskad situation.`,
      },
      {
        title: "Varför är Kali Sikaran så väl lämpat för självförsvar?",
        content:
          "Kali Sikaran härstammar ursprungligen från en värld där alla måste klara sig själva, närmare bestämt från Filippinerna för länge sedan. Kali Sikaran är ett komplext självförsvar och innehåller element som försvar mot olika vapen som knivar, batonger och andra slagvapen, samt olika attacker med bara nävarna. Du lär dig försvara dig och dina baserat på dina fysiska och psykiska förutsättningar, det finns ingen lösning som passar alla.",
      },
      {
        title: "Vem kan träna självförsvar?",
        content: `Det korta svaret är alla över 16 år. Träningarna, övningarna och allt vi gör kan enkelt anpassas till den enskildes behov. En stark man på 120 kilo har andra utmaningar när det gäller självförsvar än en lätt kvinna på 52 kilo, men våra instruktörer har lång erfarenhet och vet vad som fungerar för olika kroppar och personligheter. Vissa klubbar har anpassade träningar för barn.`,
      },
      {
        title: "Vad kan jag förvänta mig på träningen?",
        content:
          "Om du vill prova självförsvar hos oss är du varmt välkommen. Vi har plats för alla. Kom förbi och prova, så arbetar vi med dig oavsett erfarenhet eller träningsnivå. Våra erfarna och högt graderade instruktörer har kunskapen och expertisen som behövs, och vi är här för att hjälpa dig att bli tryggare och starkare. Som nybörjare är du säker, några är rädda för skador, men risken för att skadas är minimal. Folkets sport, fotboll, har mycket högre skadefrekvens än oss. Ändå erbjuder vi tuff fysisk träning, men vi förstår att alla har olika begränsningar och gränser och tar hänsyn till var och en.",
      },
      {
        title: "Är det bara att dyka upp?",
        content:
          "Ja, det är enkelt! Om du vill kan du hitta din klubb och kontakta dem direkt. Då vet de att du kommer och kan ta hand om dig från första stund.",
      },
      {
        title: "Vad behöver jag till första träningen?",
        content:
          "Vanliga träningskläder, dvs. byxor, shorts eller tights och en t-shirt, samt en vattenflaska.",
      },
    ],
  };

  const datafi = {
    title: "Usein kysytyt kysymykset",
    rows: [
      {
        title: "Miksi harjoitella itsepuolustusta?",
        content: `Itsepuolustus on asia, joista kaikilla tulisi olla perustietämys ja siitä on monia etuja. Aloitetaan ilmeisestä: yhteiskunta muuttuu ja kuulemme jatkuvasti satunnaisista uhreista ja hyökkäyksistä mediassa. Jos olet onnekas, saatat selvitä elämän ilman, että nämä tapahtumat koskettavat sinua mitenkään, mutta tilastot osoittavat että yhä useampi kohtaa satunnaista väkivaltaa, raiskauksia ja muita ei-toivottuja provosoimattomia tapahtumia. Mikäli et ole valmistautunut toimimaan, niin todennäköinen reaktiosi on paniikki ja stressi. Opettelemalla itsepuolustusta harjoitat kehoa ja mieltä reagoimaan oikein, jotta voit pysyä rauhallisena ja ratkaista tilanteen parhaalla mahdollisella tavalla. Itsepuolustusharjoittelu ei ainoastaan valmista sinua henkisesti siihen, että jotain voi tapahtua ja mitä sinun tulisi tehdä, vaan myös lisää lihasvoimaa, fyysistä kuntoa, reaktioaikaa ja antaa sinulle hiottuja refleksejä, jotka ovat avainasemassa selviytymisessä ei-toivotusta tilanteesta.`,
      },
      {
        title: "Miksi Kali Sikaran sopii hyvin itsepuolustukseen?",
        content:
          "Kali Sikaran juontaa juurensa maailmasta, jossa kaikkien täytyi selvitä omillaan, tarkemmin sanottuna Filippiineiltä kauan sitten. Kali Sikaran on monimutkainen itsepuolustusmuoto, joka sisältää useita elementtejä: puolustautumista veitsiä, patukoita ja muita lyömäaseita sekä aseettomia hyökkäyksiä vastaan. Opit puolustamaan itseäsi ja läheisiäsi itsellesi sopivalla fyysisellä ja psyykkisellä tavalla, koska yhtä kaikille sopivaa ratkaisua ei ole.",
      },
      {
        title: "Kuka voi harjoitella itsepuolustusta?",
        content: `Lyhyt vastaus on kaikki yli 18-vuotiaat. Harjoitukset, liikkeet ja kaikki mitä teemme voidaan sovittaa helposti yksilön tarpeisiin. Voimakkaalla 120 kiloisella miehellä on erilaisia haasteita kuin kevyellä 52 kiloisella naisella, mutta opettajillamme on pitkä kokemus ja tietämys siitä mikä toimii erilaisille kehoille ja persoonallisuuksille.`,
      },
      {
        title: "Mitä voin odottaa harjoituksilta?",
        content:
          "Jos haluat kokeilla itsepuolustusta meillä, olet lämpimästi tervetullut, kaikille on tilaa. Tule käymään ja kokeile, aiemmalla kokemuksella ja harjoitustasolla ei ole merkitystä. Kokeneilla ja korkealle arvostetuilla ohjaajillamme on tarvittava tietämys ja asiantuntemus, ja olemme täällä auttamassa sinua kehittymään. Aloittelijana olet turvassa, jotkut pelkäävät vammoja, mutta niiden riski on minimaalinen. Pallopeleissä on suurempi vammariski kuin itsepuolustuslajeissa. Siitä huolimatta tarjoamme fyysistä harjoittelua, mutta ymmärrämme, että kaikilla on erilaisia fyysisiä rajoja ja rajoituksia ja otamme tämän huomioon yksilöllisesti.",
      },
      {
        title: "Voinko vain saapua paikalle?",
        content:
          "Yksinkertaisesti: kyllä! Voit ottaa meihin suoraan yhteyttä. Siten tiedämme, että olet tulossa ja pystymme järjestämään harjoituksen alusta alkaen sinulle.",
      },
      {
        title: "Mitä tarvitsen ensimmäiseen harjoitukseen?",
        content:
          "Tavalliset harjoitusvaatteet eli housut, shortsit tai trikoot, t-paidan ja vesipullon.",
      },
    ],
  };

  const dataes = {
    title: "Preguntas frecuentes",
    rows: [
      {
        title: "¿Por qué entrenar autodefensa?",
        content: `La autodefensa es algo sobre lo que todos deberían tener un conocimiento básico, ya que tiene muchos beneficios. Empecemos con lo obvio: la sociedad está cambiando, y constantemente escuchamos sobre víctimas y ataques aleatorios en los medios. Si tienes suerte, podrás pasar por la vida sin ser afectado por tales eventos, pero las estadísticas indican que cada vez más personas son víctimas de violencia ciega, violaciones y otros eventos no deseados y no provocados. En tales situaciones, si no estás preparado para actuar, tu reacción probablemente será pánico y estrés. Al aprender autodefensa, entrenarás tu cuerpo y mente para reaccionar correctamente, de modo que puedas mantener la calma y manejar la situación de la mejor manera posible. El entrenamiento en autodefensa no solo te preparará mentalmente para que algo pueda suceder y qué deberías hacer en diferentes situaciones, sino que también aumentará tu fuerza muscular, condición física, tiempo de reacción y te dará reflejos entrenados, que son aspectos clave para salir bien de una situación no deseada.`,
      },
      {
        title: "¿Por qué Kali Sikaran es tan adecuado para la autodefensa?",
        content:
          "Kali Sikaran se origina en un mundo donde todos tenían que valerse por sí mismos, específicamente en Filipinas hace mucho tiempo. Kali Sikaran es una autodefensa compleja y contiene elementos como defensa contra varios tipos de armas como cuchillos, bastones y otras armas de impacto, así como diferentes ataques con los puños. Aprendes a defenderte y a los tuyos basándote en tus condiciones físicas y psicológicas, no hay una solución única que se ajuste a todos.",
      },
      {
        title: "¿Quién puede entrenar autodefensa?",
        content: `La respuesta corta es todos los mayores de 16 años. Los entrenamientos, ejercicios y todo lo que hacemos se pueden adaptar fácilmente a las necesidades individuales. Un hombre fuerte de 120 kilos tiene otros desafíos en la autodefensa que una mujer ligera de 52 kilos, pero nuestros instructores tienen mucha experiencia y saben lo que funciona para diferentes cuerpos y personalidades. Algunos clubes tienen entrenamientos adaptados para niños.`,
      },
      {
        title: "¿Qué puedo esperar en el entrenamiento?",
        content:
          "Si tienes interés en probar la autodefensa con nosotros, eres bienvenido. Tenemos espacio para todos. Ven y prueba, y trabajaremos contigo independientemente de tu experiencia o nivel de entrenamiento. Nuestros instructores experimentados y altamente calificados tienen el conocimiento y la experiencia necesarios, y estamos aquí para ayudarte a ser más seguro y fuerte. Como principiante estás seguro, algunos temen lesiones, pero la probabilidad de sufrir lesiones es mínima. El fútbol, deporte popular, tiene una frecuencia de lesiones mucho mayor que la nuestra. Aun así, ofrecemos un entrenamiento físico intenso, pero entendemos que todos tienen diferentes limitaciones y límites y tomamos en cuenta a cada individuo.",
      },
      {
        title: "¿Simplemente puedo presentarme?",
        content:
          "Sí, ¡es fácil! Si lo deseas, puedes encontrar tu club y contactarlos directamente. Así sabrán que vas a venir y podrán cuidarte desde el principio.",
      },
      {
        title: "¿Qué necesito para mi primer entrenamiento?",
        content:
          "Ropa deportiva normal, es decir, pantalones, shorts o mallas y una camiseta, y una botella de agua.",
      },
    ],
  };

  const datade = {
    title: "Häufig gestellte Fragen",
    rows: [
      {
        title: "Warum Selbstverteidigung trainieren?",
        content: `Selbstverteidigung ist etwas, über das jeder grundlegende Kenntnisse haben sollte, da sie viele Vorteile bietet. Beginnen wir mit dem Offensichtlichen: Die Gesellschaft verändert sich, und wir hören ständig von zufälligen Opfern und Angriffen in den Medien. Wenn Sie Glück haben, können Sie durch das Leben gehen, ohne von solchen Ereignissen betroffen zu sein, aber die Statistik zeigt, dass immer mehr Menschen blinder Gewalt, Vergewaltigungen und anderen unerwünschten und unprovozierten Ereignissen ausgesetzt sind. In solchen Situationen, wenn Sie nicht darauf vorbereitet sind zu handeln, wird Ihre Reaktion wahrscheinlich Panik und Stress sein. Durch das Erlernen der Selbstverteidigung trainieren Sie Körper und Geist, richtig zu reagieren, sodass Sie einen kühlen Kopf bewahren und die Situation bestmöglich bewältigen können. Das Training in Selbstverteidigung bereitet Sie nicht nur mental darauf vor, dass etwas passieren kann und was Sie in verschiedenen Situationen tun sollten, sondern erhöht auch Ihre Muskelkraft, Kondition, Reaktionszeit und gibt Ihnen eingeübte Reflexe, die Schlüsselaspekte sind, um gut aus einer unerwünschten Situation herauszukommen.`,
      },
      {
        title: "Warum ist Kali Sikaran so gut für Selbstverteidigung geeignet?",
        content:
          "Kali Sikaran stammt ursprünglich aus einer Welt, in der jeder für sich selbst sorgen musste, speziell von den Philippinen vor langer Zeit. Kali Sikaran ist eine komplexe Form der Selbstverteidigung und beinhaltet Elemente wie Verteidigung gegen verschiedene Waffen wie Messer, Schlagstöcke und andere Schlagwaffen sowie verschiedene Angriffe mit bloßen Fäusten. Sie lernen, sich und Ihre Angehörigen auf der Grundlage Ihrer physischen und psychischen Voraussetzungen zu verteidigen, es gibt keine Einheitslösung, die für alle passt.",
      },
      {
        title: "Wer kann Selbstverteidigung trainieren?",
        content: `Die kurze Antwort lautet: alle über 16 Jahre. Das Training, die Übungen und alles, was wir tun, kann leicht an die Bedürfnisse des Einzelnen angepasst werden. Ein kräftiger Mann von 120 Kilo hat andere Herausforderungen in der Selbstverteidigung als eine leichte Frau von 52 Kilo, aber unsere Trainer haben langjährige Erfahrung und wissen, was für verschiedene Körper und Persönlichkeiten funktioniert. Einige Clubs bieten angepasste Trainings für Kinder an.`,
      },
      {
        title: "Was kann ich beim Training erwarten?",
        content:
          "Wenn Sie daran interessiert sind, Selbstverteidigung bei uns auszuprobieren, sind Sie herzlich willkommen. Wir haben Platz für alle. Kommen Sie vorbei und probieren Sie es aus, und wir arbeiten mit Ihnen unabhängig von Ihrer Erfahrung oder Trainingsstufe. Unsere erfahrenen und hochgraduierten Trainer verfügen über das Wissen und die Expertise, die benötigt werden, und wir sind hier, um Ihnen zu helfen, sicherer und stärker zu werden. Als Anfänger sind Sie sicher, manche befürchten Verletzungen, aber die Chance, verletzt zu werden, ist minimal. Der Volkssport Fußball hat eine viel höhere Verletzungsrate als wir. Dennoch bieten wir hartes körperliches Training an, aber wir verstehen, dass jeder unterschiedliche Einschränkungen und Grenzen hat und nehmen Rücksicht auf jeden Einzelnen.",
      },
      {
        title: "Kann ich einfach vorbeikommen?",
        content:
          "Ja, ganz einfach! Wenn Sie möchten, können Sie Ihren Club finden und direkt mit ihnen in Kontakt treten. Dann wissen sie, dass Sie kommen und können sich von Anfang an um Sie kümmern.",
      },
      {
        title: "Was brauche ich für das erste Training?",
        content:
          "Normale Sportkleidung, also Hose, Shorts oder Leggings und ein T-Shirt, sowie eine Wasserflasche.",
      },
    ],
  };

  const styles = {
    bgColor: "black",
    titleTextColor: "White",
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "#ED1C24",
    rowContentTextSize: "20px",
  };

  const config = {
    animate: true,
    tabFocus: true,
  };

  return (
    <div className="faq-row-wrapper px-5">
      <Faq data={datafi} styles={styles} config={config}></Faq>
    </div>
  );
};

export default FAQSection;
